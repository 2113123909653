import { whiteLabelNames } from "src/app/appsettings";
import { Config } from "../synlab-access-ui-components/model/config.model";

export function formatLocale(locale: string, config?: Config, allowNull?: boolean) {
  if (!locale) {
    if (allowNull) return null;

    if (config) {
      locale = config.DefaultLanguage;
    } else {
      locale = "en";
    }
  }

  const tenant = config?.WhiteLabelName?.toLowerCase();
  const configLocale = config?.DefaultLanguage;
  const localeCode = locale.replace(/[^a-zA-Z]/g, "").substring(0, 2).toLowerCase();


  // FI tenant use 'en-FI.json' translation file
  if (tenant == whiteLabelNames.FINLAND && localeCode == "en") {
    return configLocale ?? "en-FI";
  }

  return localeCode;
}
